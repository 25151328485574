import React from "react";
import { range } from "remeda";

import styles from "./calendar-week.module.scss";

interface GridProps {
  days: number;
}

export function Grid({ days }: GridProps) {
  return (
    <div className={styles.grid}>
      <div className={styles.days}>
        {range(0, days).map((dayIndex) => (
          <div key={dayIndex} className={styles.day} />
        ))}
      </div>
      <div className={styles.hours}>
        {range(0, 26).map((hourIndex) => (
          <div key={hourIndex} className={styles.hour} />
        ))}
      </div>
    </div>
  );
}
