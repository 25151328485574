import {
  ElementDragPayload,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { DragLocationHistory } from "@atlaskit/pragmatic-drag-and-drop/types";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { merge } from "remeda";
import invariant from "tiny-invariant";

import { useEvents } from "entities/events";
import { TaskModel } from "entities/tasks";

import { EventEntity, ZoneEntity } from "shared/database";
import { roundMinutesToSlot } from "shared/libs/time";

import styles from "./calendar-week.module.scss";

import { CurrentTimeDayBar } from "./current-time-day-bar";
import { Event } from "./event";
import { EventPreview } from "./event-preview";

interface DayEventsProps {
  startDay: DateTime;
  dayIndex: number;
  events: EventEntity[];
  height: number;
  tasks: Map<string, TaskModel>;
  zones: ZoneEntity[];
  onShowTaskInOutline?: (taskId: string) => void;
}

export function DayEvents({
  startDay,
  dayIndex,
  events,
  height,
  tasks,
  zones,
  onShowTaskInOutline,
}: DayEventsProps) {
  const [dayElement, setDayElement] = useState<HTMLDivElement | null>(null);
  const [previewDetails, setPreviewDetails] = useState<{
    startDateTime: DateTime;
    actualDuration: number;
  } | null>(null);

  const { pin } = useEvents();
  const day = startDay.plus({ day: dayIndex });

  const estimatePreviewDetails = useCallback(
    (location: DragLocationHistory, source: ElementDragPayload) => {
      const data: {
        calendarTop: number;
        calendarScrollTop: number;
        eventPreviewTopOffset: number;
        cursorY: number;
      } = location.current.dropTargets
        .map((t) => t.data)
        .reduce((p, c) => merge(p, c)) as never;

      const dayTop =
        data.calendarScrollTop +
        (data.cursorY - data.calendarTop) +
        -data.eventPreviewTopOffset;

      const dayMinutes = (dayTop / height) * 60 * 24;
      const roundedDayMinutes = Math.floor(dayMinutes / 15) * 15;

      return {
        startDateTime: day.plus({ minute: roundedDayMinutes }),
        actualDuration: source.data.actualDuration as number,
        estimatedDuration: source.data.estimatedDuration as number,
      };
    },
    [day, height],
  );

  useEffect(() => {
    if (!dayElement) return;

    return dropTargetForElements({
      element: dayElement,
      getData: ({ input }) => ({ cursorY: input.clientY }),
      onDrag: ({ location, source }) => {
        setPreviewDetails(estimatePreviewDetails(location, source));
      },
      onDragLeave: () => setPreviewDetails(null),
      onDrop: ({ location, source }) => {
        const previewDetails = estimatePreviewDetails(location, source);

        pin(
          source.data.id as never,
          previewDetails.startDateTime,
          previewDetails.estimatedDuration,
        );

        setPreviewDetails(null);
      },
    });
  }, [day, dayElement, estimatePreviewDetails, height, pin]);

  const eventLines = useMemo(
    function evaluateEventLines() {
      const eventLines = new Map<
        EventEntity,
        { line: number; total: number }
      >();
      const activeEvents = new Set<EventEntity>();

      const sortedEvents = events.sort((a, b) => {
        if (a.startDate.getTime() !== b.startDate.getTime())
          return a.startDate.getTime() - b.startDate.getTime();

        const aDuration = a.actualDuration ?? a.duration;
        const bDuration = b.actualDuration ?? b.duration;
        if (aDuration !== bDuration) return bDuration - aDuration;

        const taskA = tasks.get(a.taskId);
        invariant(taskA);

        const taskB = tasks.get(b.taskId);
        invariant(taskB);

        return taskA.title.localeCompare(taskB.title);
      });

      for (const event of sortedEvents) {
        const eventStartDateTime = DateTime.fromJSDate(event.startDate);

        // Remove events that end before current event starts
        for (const activeEvent of activeEvents) {
          const activeEventStartDateTime = DateTime.fromJSDate(
            activeEvent.startDate,
          );
          const activeEventEndDateTime = activeEventStartDateTime.plus({
            minute: roundMinutesToSlot(
              activeEvent.actualDuration ?? activeEvent.duration,
            ),
          });

          if (activeEventEndDateTime <= eventStartDateTime) {
            activeEvents.delete(activeEvent);
          }
        }

        // Find first available line
        let line = 0;
        const usedLines = new Set<number>();

        for (const activeEvent of activeEvents) {
          const activeLine = eventLines.get(activeEvent)?.line ?? 0;
          usedLines.add(activeLine);
        }

        while (usedLines.has(line)) {
          line++;
        }

        activeEvents.add(event);
        const total = activeEvents.size;

        // Update all active events with the new total
        for (const activeEvent of activeEvents) {
          const currentLine =
            activeEvent === event
              ? line
              : (eventLines.get(activeEvent)?.line ?? 0);
          eventLines.set(activeEvent, {
            line: currentLine,
            total: Math.max(total, eventLines.get(activeEvent)?.total ?? 0),
          });
        }
      }

      return eventLines;
    },
    [events, tasks],
  );

  return (
    <div
      className={styles.day}
      ref={setDayElement}
      style={{
        gridColumn: `day-start ${dayIndex + 1} / day-end ${dayIndex + 1}`,
      }}
    >
      {events.map((event) => {
        const task = tasks.get(event.taskId);
        if (!task) {
          console.error(`Unable to find task for event with id ${event.id}`);
          return null;
        }

        const zone = zones.find((z) => z.id === task.zoneId);

        return (
          <Event
            key={event.id}
            event={event}
            task={task}
            zone={zone}
            height={height}
            parallelEvents={eventLines.get(event)?.total ?? 0}
            parallelEventIndex={eventLines.get(event)?.line ?? 0}
            onShowTaskInOutline={onShowTaskInOutline}
          />
        );
      })}
      {DateTime.now().startOf("day").equals(day) && (
        <CurrentTimeDayBar height={height} />
      )}
      {previewDetails && (
        <EventPreview
          startDateTime={previewDetails.startDateTime}
          duration={previewDetails.actualDuration}
          height={height}
        />
      )}
    </div>
  );
}
