import { DateTime } from "luxon";
import { entries, groupBy } from "remeda";
import { RRule } from "rrule";
import invariant from "tiny-invariant";
import { v4 as uuidv4 } from "uuid";

import { markEventAsCompleted } from "entities/events";
import { getOutlineTasksQuery, isRecurringTask } from "entities/tasks";

import {
  BaseTaskEntity,
  RecurringPlan,
  Session,
  TaskEntity,
  database,
} from "shared/database";

async function getChildren(parentId: string) {
  return database.tasks.where("parentId").equals(parentId).toArray();
}

export async function removeTask(task: BaseTaskEntity) {
  return database.transaction(
    "readwrite",
    [database.tasks, database.events],
    async () => {
      const children = await getChildren(task.id);
      await Promise.all(children.map(removeTask));

      const taskSessionIds = task.plan?.sessions.map((s) => s.id) ?? [];
      await database.events.where("sessionId").anyOf(taskSessionIds).delete();

      await database.tasks.delete(task.id);

      await reorderTaskChildren(task.parentId);
    },
  );
}

export async function updateTask(
  task: BaseTaskEntity,
  changes: {
    name?: string;
    notes?: string;
    zoneId?: string;
    duration?: number;
    parentId?: string | null;
    order?: number;
    priority?: number;
  },
) {
  await database.tasks.update(task.id, changes);
}

export async function collapseTask(task: BaseTaskEntity) {
  return database.transaction("readwrite", database.tasks, async () => {
    const children = await getChildren(task.id);
    await Promise.all(children.map(collapseTask));

    await database.tasks.update(task.id, { collapsed: true });
  });
}

export async function expandTask(task: BaseTaskEntity) {
  await database.tasks.update(task.id, { collapsed: false });
}

export async function logCompletedTasks() {
  await database.tasks
    .filter(
      (t) =>
        (t.location === "inbox" || t.location === "outline") &&
        t.completedAt !== undefined,
    )
    .modify((t) => {
      Object.assign(t, {
        location: "journal",
        loggedAt: DateTime.now().toJSDate(),
      });
    });
}

export async function scheduleTaskSessions({ id: taskId }: BaseTaskEntity) {
  return database.transaction("readwrite", database.tasks, async () => {
    const task = await database.tasks.get(taskId);
    invariant(task);

    if (!isRecurringTask(task)) {
      return;
    }

    const rRule = RRule.fromString(task.plan.rrule);
    const today = DateTime.now().startOf("day");

    const ranges = groupBy(
      rRule
        .between(today.toJSDate(), today.plus({ days: 21 }).toJSDate(), true)
        .map((d) => DateTime.fromJSDate(d))
        .map((d) => {
          const startDate = d.startOf(task.plan.groupType);
          if (task.plan.groupType === "day") return { startDate, range: 1 };
          if (task.plan.groupType === "week") return { startDate, range: 7 };
          if (task.plan.groupType === "month")
            return { startDate, range: startDate.daysInMonth! };
          if (task.plan.groupType === "year")
            return { startDate, range: startDate.daysInYear! };

          return { startDate, range: 1 };
        }),
      (d) => `${d.startDate.toISO()}-${d.range}`,
    );

    const sessions = task.plan.sessions.filter((s) => {
      if (s.completedAt) {
        const sessionDate = DateTime.fromISO(s.startDate);
        const weekAgo = DateTime.now().minus({ days: 7 });
        return sessionDate >= weekAgo;
      }
      return s.completedAt !== undefined;
    });

    for (const [, group] of entries(ranges)) {
      const dateRange = group[0];

      const existingSession = task.plan.sessions.find(
        (s) =>
          s.startDate === dateRange.startDate.toISODate() &&
          s.days === dateRange.range,
      );

      sessions.push(
        existingSession
          ? existingSession
          : {
              id: uuidv4(),
              type: "timeBound",
              startDate: dateRange.startDate.toISODate()!,
              days: dateRange.range,
              skipped: false,
            },
      );
    }

    await database.tasks.update(taskId, {
      plan: {
        ...task.plan,
        sessions,
      },
    });
  });
}

export async function completeTask(
  { id }: BaseTaskEntity,
  completedAt: Date | undefined,
) {
  return database.transaction(
    "readwrite",
    [database.tasks, database.events],
    async () => {
      try {
        const task = await database.tasks.get(id);
        invariant(task, `Task with id ${id} not found`);

        if (!task.plan) {
          await markTaskAsCompleted(task, completedAt);
          return;
        }

        if (isRecurringTask(task)) {
          const activeSession = task.plan.sessions.find(
            (s) =>
              DateTime.now() >= DateTime.fromISO(s.startDate) &&
              DateTime.now() <
                DateTime.fromISO(s.startDate).plus({ day: s.days }),
          );

          if (!activeSession)
            throw new Error(`Can't find active session for task ${id}`);

          await completeTaskSession(task, activeSession, completedAt);
          return;
        }

        if (task.plan.type === "flexible") {
          await completeTaskSession(task, task.plan.sessions[0], completedAt);
          return;
        }

        throw new Error("Unknown plan type");
      } catch (error) {
        if (error instanceof Error) {
          throw new Error(`Failed to complete task: ${error.message}`);
        }
        throw error;
      }
    },
  );
}

/**
 * Completes a specific session of a task and handles associated events.
 * For flexible tasks, this also completes the entire task.
 * @param task The task containing the session
 * @param session The specific session to complete
 * @param completedAt The completion date, or undefined to mark as incomplete
 */
export async function completeTaskSession(
  { id: taskId }: BaseTaskEntity,
  { id: sessionId }: Session,
  completedAt: Date | undefined,
) {
  return database.transaction(
    "readwrite",
    [database.tasks, database.events],
    async () => {
      const task = await database.tasks.get(taskId);
      if (!task) throw new Error(`Can't find task with id ${taskId}`);

      const session = task.plan?.sessions.find((s) => s.id === sessionId);
      if (!session) throw new Error(`Can't find session with id ${sessionId}`);

      await markSessionAsCompleted(task, session, completedAt);

      const sessionEvent = await database.events
        .where("sessionId")
        .equals(sessionId)
        .first();

      if (!sessionEvent)
        throw new Error(`Can't find event for session with id ${sessionId}`);

      await markEventAsCompleted(sessionEvent, !!completedAt);

      if (task.plan?.type === "flexible")
        await markTaskAsCompleted(task, completedAt);
    },
  );
}

async function markSessionAsCompleted(
  { id: taskId }: BaseTaskEntity,
  { id: sessionId }: Session,
  completedAt: Date | undefined,
) {
  return database.transaction("readwrite", database.tasks, async () => {
    const task = await database.tasks.get(taskId);
    invariant(task);

    const session = task.plan?.sessions.find((s) => s.id === sessionId);
    if (!session) throw new Error(`Can't find session with id ${sessionId}`);

    session.completedAt = completedAt;

    await database.tasks.update(taskId, { plan: task.plan });
  });
}

async function markTaskAsCompleted(
  { id }: BaseTaskEntity,
  completedAt: Date | undefined,
) {
  await database.transaction("readwrite", database.tasks, async () => {
    const task = await database.tasks.get(id);
    invariant(task, `Task with id ${id} not found`);

    const children = await getChildren(id);
    const allChildrenCompleted = children.every(
      (child) => child.completedAt !== undefined,
    );

    if (!allChildrenCompleted && completedAt !== undefined) {
      throw new Error(
        "Cannot complete a parent task when children are incomplete",
      );
    }

    await database.tasks.update(id, { completedAt });
  });
}

export async function resetTasksPriorities() {
  await database.transaction("readwrite", [database.tasks], async () => {
    const tasksWithPriority = await getOutlineTasksQuery()
      .filter((t) => t.priority !== undefined)
      .sortBy("priority");

    let priority = 1;
    const updates = tasksWithPriority.map((t) => {
      return {
        key: t.id,
        changes: {
          priority: priority++ * 10000,
        },
      };
    });

    await database.tasks.bulkUpdate(updates);
  });
}

export async function setTaskPlan(
  task: BaseTaskEntity,
  repeat:
    | { type: "notPlanned" }
    | { type: "flexible" }
    | {
        type: "recurring";
        rrule: string;
        groupType: RecurringPlan["groupType"];
      },
) {
  return database.transaction(
    "readwrite",
    [database.tasks, database.events],
    async () => {
      await database.events.where("taskId").equals(task.id).delete();

      switch (repeat.type) {
        case "notPlanned":
          await database.tasks.update(task.id, { plan: undefined });
          break;
        case "flexible":
          await database.tasks.update(task.id, {
            plan: {
              type: "flexible",
              sessions: [{ id: uuidv4(), type: "flexible", skipped: false }],
            },
          });
          break;
        case "recurring":
          await database.tasks.update(task.id, {
            plan: {
              type: "recurring",
              rrule: repeat.rrule,
              groupType: repeat.groupType,
              sessions: [],
            },
          });
          break;
        default:
          throw new Error("Unknown repeat type");
      }

      scheduleTaskSessions(task);
    },
  );
}

export async function createTask(
  name: string,
  parentId: string | null,
  order: number,
) {
  return database.transaction("readwrite", [database.tasks], async () => {
    const task: TaskEntity = {
      id: uuidv4(),
      name,
      order,
      parentId,
      duration: 60,
      collapsed: false,
      location: "outline" as const,
    };

    const taskId = await database.tasks.add(task);

    await reorderTaskChildren(parentId, taskId, order);

    return taskId;
  });
}

export async function reorderTaskChildren(
  parentId: string | null,
  taskId?: string,
  taskSlot?: number,
) {
  await database.transaction("readwrite", [database.tasks], async () => {
    const tasksToReorder = await getOutlineTasksQuery()
      .filter((t) => t.parentId === parentId)
      .sortBy("order");

    let taskOrder = 0;
    const updates = tasksToReorder.map((t) => {
      if (taskOrder === taskSlot) taskOrder++;

      return {
        key: t.id,
        changes: {
          order: t.id === taskId ? taskSlot! : taskOrder++,
        },
      };
    });

    await database.tasks.bulkUpdate(updates);
  });
}

export async function moveTask(
  task: BaseTaskEntity,
  parent: TaskEntity | null,
  order: number,
) {
  await database.transaction("readwrite", [database.tasks], async () => {
    const previousParentId = task.parentId;

    await collapseTask(task);
    await updateTask(task, {
      parentId: parent?.id ?? null,
      order,
    });

    await reorderTaskChildren(parent?.id ?? null, task.id, order);
    await reorderTaskChildren(previousParentId);

    if (parent) await expandTask(parent);
  });
}

export async function skipTaskSession(
  { id: taskId }: BaseTaskEntity,
  { id: sessionId }: Session,
) {
  return database.transaction(
    "readwrite",
    [database.tasks, database.events],
    async () => {
      const task = await database.tasks.get(taskId);
      if (!task) throw new Error(`Can't find task with id ${taskId}`);

      if (!isRecurringTask(task)) {
        throw new Error(`Task ${taskId} is not a recurring task`);
      }

      const session = task.plan.sessions.find((s) => s.id === sessionId);
      if (!session) throw new Error(`Can't find session with id ${sessionId}`);

      await database.events.where("sessionId").equals(sessionId).delete();

      await database.tasks.update(taskId, {
        plan: {
          ...task.plan,
          sessions: task.plan.sessions.map((s) =>
            s.id === sessionId ? { ...s, skipped: true } : s,
          ),
        },
      });
    },
  );
}
