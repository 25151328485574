import { clsx } from "clsx";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import styles from "./control-panel.module.scss";

import { Button } from "./button";
import { Divider } from "./divider";

interface Props {
  children: React.ReactNode;
}

function ControlPanel({ children }: Props) {
  const [isOpen, setIsOpen] = useState(true);

  useHotkeys("h", () => setIsOpen((prev) => !prev));

  return (
    <div className={styles.container}>
      <motion.div
        className={clsx(styles.panel, { [styles.open]: isOpen })}
        animate={{
          y: isOpen ? 0 : 10,
          opacity: isOpen ? 1 : 0,
          visibility: isOpen ? "visible" : "hidden",
        }}
      >
        {children}
      </motion.div>
    </div>
  );
}

export default Object.assign(ControlPanel, { Button, Divider });
