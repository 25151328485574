import {
  FloatingPortal,
  offset,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import { motion } from "framer-motion";
import React, { ReactNode, useCallback, useState } from "react";

import styles from "./context-menu.module.scss";

import { ContextMenuContext } from "./context-menu-context";

interface ContextMenuProps {
  children: ReactNode;
  content: ReactNode;
  className?: string;
}

export function ContextMenu({
  children,
  content,
  className,
}: ContextMenuProps) {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "bottom-start",
    middleware: [offset(4), shift({ padding: 14 })],
    strategy: "fixed",
  });

  const dismiss = useDismiss(context);

  const { getFloatingProps } = useInteractions([dismiss]);

  const handleContextMenu = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      const x = event.clientX;
      const y = event.clientY;

      refs.setPositionReference({
        getBoundingClientRect() {
          return {
            x,
            y,
            top: y,
            right: x,
            bottom: y,
            left: x,
            width: 0,
            height: 0,
          };
        },
      });
      setIsOpen(true);
    },
    [refs],
  );

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <>
      <div onContextMenu={handleContextMenu} className={className}>
        {children}
      </div>
      {isOpen && (
        <FloatingPortal>
          <div className={styles.overlay}>
            <div
              ref={refs.setFloating}
              {...getFloatingProps()}
              style={floatingStyles}
              className={styles.contextMenu}
            >
              <motion.div
                initial={{ opacity: 0, scale: 0.85 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.85 }}
                transition={{ duration: 0.15 }}
              >
                <div className={styles.panel}>
                  <ContextMenuContext.Provider value={{ close: handleClose }}>
                    {content}
                  </ContextMenuContext.Provider>
                </div>
              </motion.div>
            </div>
          </div>
        </FloatingPortal>
      )}
    </>
  );
}
