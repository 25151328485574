import { TimeMapEntity, database } from "shared/database";

export async function setDefaultTimeMap(timeMap: TimeMapEntity) {
  // First, remove default from all other time maps
  await database.timeMaps
    .filter((map) => map.isDefault && map.id !== timeMap.id)
    .modify((map) => {
      map.isDefault = false;
    });

  // Set the new default
  await database.timeMaps.update(timeMap.id, { isDefault: true });
}
