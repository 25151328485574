import { motion, useMotionValue } from "framer-motion";
import React, { useEffect } from "react";

import styles from "./calendar-week.module.scss";

import { estimateCurrentTimeBarHeight } from "./utils";

interface CurrentTimeDayBarProps {
  height: number;
}

export function CurrentTimeDayBar({ height }: CurrentTimeDayBarProps) {
  const currentHeight = useMotionValue(estimateCurrentTimeBarHeight(height));

  useEffect(() => {
    const intervalId = setInterval(
      () => currentHeight.set(estimateCurrentTimeBarHeight(height)),
      1000,
    );

    return () => clearInterval(intervalId);
  }, [currentHeight, height]);

  return (
    <div className={styles.dayNowContainer}>
      <motion.div className={styles.dayNow} style={{ y: currentHeight }} />
    </div>
  );
}
