import React, { useState } from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { BsPencil, BsTrash } from "react-icons/bs";

import { TimeMapEntity } from "shared/database";

import styles from "./time-maps.module.scss";

import { TimeMapForm } from "./time-map-form";

import { setDefaultTimeMap } from "../model/mutators";
import { useTimeMaps } from "../model/use-time-maps";

export function TimeMaps() {
  const { timeMaps, createTimeMap, updateTimeMap, deleteTimeMap } =
    useTimeMaps();
  const [editingTimeMap, setEditingTimeMap] = useState<TimeMapEntity | null>(
    null,
  );
  const [isCreating, setIsCreating] = useState(false);

  if (!timeMaps) return null;

  const handleCreate = async (timeMap: Omit<TimeMapEntity, "id">) => {
    await createTimeMap({ ...timeMap, isDefault: !timeMaps.length });
    setIsCreating(false);
  };

  const handleUpdate = async (timeMap: Omit<TimeMapEntity, "id">) => {
    if (!editingTimeMap) return;
    await updateTimeMap({ ...timeMap, id: editingTimeMap.id });
    setEditingTimeMap(null);
  };

  const handleSetDefault = async (timeMap: TimeMapEntity) => {
    await setDefaultTimeMap(timeMap);
  };

  if (isCreating) {
    return (
      <TimeMapForm
        onSubmit={handleCreate}
        onCancel={() => setIsCreating(false)}
      />
    );
  }

  if (editingTimeMap) {
    return (
      <TimeMapForm
        timeMap={editingTimeMap}
        onSubmit={handleUpdate}
        onCancel={() => setEditingTimeMap(null)}
      />
    );
  }

  return (
    <div className={styles.container}>
      <button onClick={() => setIsCreating(true)}>Create Time Map</button>

      <div className={styles.list}>
        {timeMaps.map((timeMap) => (
          <div key={timeMap.id} className={styles.timeMap}>
            <div className={styles.timeMapName}>{timeMap.name}</div>
            <div className={styles.timeMapActions}>
              <button
                onClick={() => handleSetDefault(timeMap)}
                className={styles.defaultButton}
              >
                {timeMap.isDefault ? <AiFillStar /> : <AiOutlineStar />}
              </button>
              <button onClick={() => setEditingTimeMap(timeMap)}>
                <BsPencil />
              </button>
              <button onClick={() => deleteTimeMap(timeMap.id)}>
                <BsTrash />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
