import React, { useState } from "react";

import { TimeMapEntity } from "shared/database";

import styles from "./time-maps.module.scss";

import {
  generateTimeOptions,
  timeSlotToTimeString,
  timeStringToTimeSlot,
} from "../lib/time-slots";

interface Props {
  timeMap?: TimeMapEntity;
  onSubmit: (timeMap: Omit<TimeMapEntity, "id">) => void;
  onCancel: () => void;
}

interface TimeSlot {
  startTimeSlot: number;
  endTimeSlot: number;
}

const weekDays = [
  { key: "monday", label: "Monday" },
  { key: "tuesday", label: "Tuesday" },
  { key: "wednesday", label: "Wednesday" },
  { key: "thursday", label: "Thursday" },
  { key: "friday", label: "Friday" },
  { key: "saturday", label: "Saturday" },
  { key: "sunday", label: "Sunday" },
] as const;

const defaultTimeSlots = {
  startTimeSlot: 32, // 8:00
  endTimeSlot: 72, // 18:00
};

export function TimeMapForm({ timeMap, onSubmit, onCancel }: Props) {
  const [name, setName] = useState(timeMap?.name ?? "");
  const [timeSlots, setTimeSlots] = useState(() => {
    if (timeMap) {
      return Object.fromEntries(
        weekDays.map(({ key }) => [
          key,
          {
            startTimeSlot: timeMap[key].startTimeSlot,
            endTimeSlot: timeMap[key].endTimeSlot,
          },
        ]),
      );
    }

    return Object.fromEntries(
      weekDays.map(({ key }) => [key, { ...defaultTimeSlots }]),
    );
  });
  const [copiedTimeSlot, setCopiedTimeSlot] = useState<TimeSlot | null>(null);

  const handleCopyTimeSlot = (key: string) => {
    setCopiedTimeSlot(timeSlots[key]);
  };

  const handlePasteTimeSlot = (key: string) => {
    if (!copiedTimeSlot) return;

    setTimeSlots((prev) => ({
      ...prev,
      [key]: { ...copiedTimeSlot },
    }));
  };

  const timeOptions = generateTimeOptions();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({
      name,
      isDefault: timeMap?.isDefault ?? false,
      monday: timeSlots.monday,
      tuesday: timeSlots.tuesday,
      wednesday: timeSlots.wednesday,
      thursday: timeSlots.thursday,
      friday: timeSlots.friday,
      saturday: timeSlots.saturday,
      sunday: timeSlots.sunday,
    });
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.formHeader}>
        {timeMap ? "Edit Time Map" : "Create Time Map"}
      </div>

      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Time Map Name"
        required
      />

      <div className={styles.formDays}>
        {weekDays.map(({ key, label }) => (
          <div key={key} className={styles.formDay}>
            <div className={styles.formDayName}>{label}</div>
            <div className={styles.formDayTimes}>
              <select
                className={styles.select}
                value={timeSlotToTimeString(timeSlots[key].startTimeSlot)}
                onChange={(e) =>
                  setTimeSlots((prev) => ({
                    ...prev,
                    [key]: {
                      ...prev[key],
                      startTimeSlot: timeStringToTimeSlot(e.target.value),
                    },
                  }))
                }
              >
                {timeOptions.map((time) => (
                  <option key={time} value={time}>
                    {time}
                  </option>
                ))}
              </select>
              <span>to</span>
              <select
                className={styles.select}
                value={timeSlotToTimeString(timeSlots[key].endTimeSlot)}
                onChange={(e) =>
                  setTimeSlots((prev) => ({
                    ...prev,
                    [key]: {
                      ...prev[key],
                      endTimeSlot: timeStringToTimeSlot(e.target.value),
                    },
                  }))
                }
              >
                {timeOptions.map((time) => (
                  <option key={time} value={time}>
                    {time}
                  </option>
                ))}
              </select>
              <button
                type="button"
                className={styles.copyButton}
                onClick={() => handleCopyTimeSlot(key)}
              >
                Copy
              </button>
              {copiedTimeSlot && (
                <button
                  type="button"
                  className={styles.pasteButton}
                  onClick={() => handlePasteTimeSlot(key)}
                >
                  Paste
                </button>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className={styles.formActions}>
        <button type="button" onClick={onCancel}>
          Cancel
        </button>
        <button type="submit">
          {timeMap ? "Save Changes" : "Create Time Map"}
        </button>
      </div>
    </form>
  );
}
