import { clsx } from "clsx";
import React, { useContext, useState } from "react";
import {
  BsArrowRepeat,
  BsCalendar2Check,
  BsCalendar2Minus,
} from "react-icons/bs";
import { TbSum } from "react-icons/tb";

import { TaskContext, updateTask } from "entities/tasks";
import { TaskPlanRadioInput } from "entities/tasks/ui/task-plan-radio-input";
import { useZones } from "entities/zones";

import { Popover } from "shared/ui";
import { RadioInput } from "shared/ui/radio-input";

import styles from "./task-settings.module.scss";

interface TaskSettingsProps {
  className?: string;
}

export function TaskSettings({ className }: TaskSettingsProps) {
  const [showPopover, setShowPopover] = useState(false);
  const [buttonElement, setButtonElement] = useState<HTMLDivElement | null>(
    null,
  );

  const { zones, api: zonesApi } = useZones();
  const { task } = useContext(TaskContext);
  if (!task) return;

  const zone = task.zoneId ? zonesApi?.tryGetById(task.zoneId) : null;

  const hasChildren = task.children.length !== 0;
  const duration = task.duration;

  return (
    <span className={clsx(styles.settings, className)}>
      <div
        ref={setButtonElement}
        className={styles.info}
        onClick={() => setShowPopover((prev) => !prev)}
        onDoubleClick={(e) => e.stopPropagation()}
      >
        <div className={styles.duration}>
          {hasChildren && <TbSum className={styles.sum} />}
          {hasChildren
            ? formatDuration(duration)
            : formatDuration(task.duration)}
        </div>
        <div
          className={clsx(styles.status, { [styles.isParent]: hasChildren })}
        >
          {task.task.plan === undefined && (
            <BsCalendar2Minus className={styles.notPlanned} />
          )}
          {task.task.plan?.type === "flexible" && <BsCalendar2Check />}
          {task.task.plan?.type === "recurring" && (
            <BsArrowRepeat className={styles.repeat} />
          )}
        </div>
        <div
          className={styles.zone}
          style={{ backgroundColor: zone?.color }}
        ></div>
      </div>
      <Popover
        targetElement={buttonElement}
        onClose={() => setShowPopover(false)}
        className={styles.popover}
        isOpen={showPopover}
      >
        <div
          className={styles.container}
          onPaste={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
          onDoubleClick={(e) => e.stopPropagation()}
        >
          <div className={styles.zone}>
            <RadioInput
              label="Зона"
              value={task.task.zoneId}
              onChange={(value) => updateTask(task?.task, { zoneId: value })}
            >
              <RadioInput.Option value={undefined}>
                Родительская
              </RadioInput.Option>
              {zones.map((zone) => (
                <RadioInput.Option
                  key={zone.id}
                  value={zone.id}
                  activeColor={zone.color}
                >
                  {zone.name}
                </RadioInput.Option>
              ))}
            </RadioInput>
          </div>
          <TaskPlanRadioInput key={task.task?.plan?.type} task={task} />
        </div>
      </Popover>
    </span>
  );
}

export function formatDuration(minutes: number) {
  if (minutes % 60 === 0) return `${minutes / 60}ч`;

  return minutes >= 60
    ? `${Math.floor(minutes / 60)}ч ${minutes % 60}м`
    : `${minutes}м`;
}
