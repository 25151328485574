import { DateTime } from "luxon";
import React from "react";
import { range } from "remeda";

import styles from "./calendar-week.module.scss";

interface TimeColumnProps {
  today: DateTime;
}

export function TimeColumn({ today }: TimeColumnProps) {
  return (
    <div className={styles.timeColumn}>
      {range(0, 24).map((hour) => (
        <span key={hour} className={styles.hour}>
          {today.plus({ hour }).toLocaleString(DateTime.TIME_24_SIMPLE)}
        </span>
      ))}
    </div>
  );
}
