import React from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { IconType } from "react-icons";

import styles from "./control-panel.module.scss";

interface Props {
  icon: IconType;
  label?: string;
  hotkey?: string;
  onClick: () => void;
}

export function Button({ icon: Icon, label, onClick, hotkey }: Props) {
  useHotkeys(hotkey ?? "", onClick, {
    preventDefault: true,
    enabled: !!hotkey,
  });

  return (
    <div className={styles.button} onClick={onClick}>
      <Icon />
      {label && <span>{label}</span>}
    </div>
  );
}
