import { useLiveQuery } from "dexie-react-hooks";

import { TimeMapEntity, database } from "shared/database";

export function useTimeMaps() {
  const timeMaps = useLiveQuery(() => database.timeMaps.toArray());

  const createTimeMap = async (timeMap: Omit<TimeMapEntity, "id">) => {
    await database.timeMaps.add({
      ...timeMap,
      id: crypto.randomUUID(),
    });
  };

  const updateTimeMap = async (timeMap: TimeMapEntity) => {
    await database.timeMaps.put(timeMap);
  };

  const deleteTimeMap = async (id: string) => {
    await database.timeMaps.delete(id);
  };

  return {
    timeMaps,
    createTimeMap,
    updateTimeMap,
    deleteTimeMap,
  };
}
