import {
  flip,
  offset,
  shift,
  size,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import clsx from "clsx";
import { motion } from "framer-motion";
import React, { ReactNode } from "react";
import { createPortal } from "react-dom";

import styles from "./popover.module.scss";

interface Props {
  targetElement: HTMLElement | null;
  children: ReactNode;
  verticalOffset?: number;
  onClose?: () => void;
  className?: string;
  isOpen: boolean;
}

export function Popover({
  targetElement,
  children,
  verticalOffset,
  onClose,
  className,
  isOpen,
}: Props) {
  const { refs, floatingStyles, context } = useFloating({
    elements: {
      reference: targetElement,
    },
    open: isOpen,
    onOpenChange: onClose,
    middleware: [
      offset(verticalOffset ?? 14),
      shift({ padding: 14 }),
      flip({ padding: 14 }),
      size({
        apply({ availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight - 8}px`,
          });
        },
        padding: 14,
      }),
    ],
  });

  const dismiss = useDismiss(context);
  const { getFloatingProps } = useInteractions([dismiss]);

  if (!isOpen) return null;

  return createPortal(
    <div
      ref={refs.setFloating}
      style={{ ...floatingStyles, zIndex: 9999 }}
      {...getFloatingProps()}
    >
      <motion.div
        initial={{ opacity: 0.7, scale: 0.7 }}
        animate={{ opacity: 1, scale: 1 }}
        className={clsx(styles.panel, className)}
        style={{ maxHeight: "inherit", overflowY: "auto", padding: 8 }}
      >
        {children}
      </motion.div>
    </div>,
    document.body,
  );
}
