import { createContext, useContext } from "react";

interface ContextMenuContextValue {
  close: () => void;
}

export const ContextMenuContext = createContext<ContextMenuContextValue | null>(
  null,
);

export function useContextMenu() {
  const context = useContext(ContextMenuContext);
  if (!context) {
    throw new Error("useContextMenu must be used within a ContextMenuProvider");
  }
  return context;
}
