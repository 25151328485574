import { DateTime } from "luxon";
import React from "react";

import styles from "./calendar-week.module.scss";

import { Header } from "./header";
import { Schedule } from "./schedule";

interface CalendarWeekProps {
  startDay: DateTime;
  days: number;
  onShowTaskInOutline?: (taskId: string) => void;
}

export function CalendarWeek({
  startDay,
  days,
  onShowTaskInOutline,
}: CalendarWeekProps) {
  return (
    <div className={styles.week}>
      <Header startDay={startDay} days={days} />
      <Schedule
        startDay={startDay}
        days={days}
        onShowTaskInOutline={onShowTaskInOutline}
      />
    </div>
  );
}
