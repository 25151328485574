import { motion } from "framer-motion";
import React, { useContext } from "react";
import { HiChevronRight } from "react-icons/hi";

import styles from "./task-collapse.module.scss";

import { TaskContext, collapseTask, expandTask } from "../model";

export function TaskCollapse() {
  const { task } = useContext(TaskContext);
  if (!task || task.isCompletable) return null;

  function handleClick(e: React.MouseEvent) {
    e.stopPropagation();

    if (!task) return;

    if (task.isCollapsed) {
      void expandTask(task.task);
    } else {
      void collapseTask(task.task);
    }
  }

  return (
    <motion.div
      className={styles.collapse}
      initial={false}
      animate={{ rotate: task.isCollapsed ? 0 : 90 }}
      transition={{ duration: 0.1 }}
      onClick={handleClick}
      onDoubleClick={(e) => e.stopPropagation()}
    >
      <HiChevronRight className={styles.icon} />
    </motion.div>
  );
}
