import { clsx } from "clsx";
import { DateTime } from "luxon";
import React from "react";
import { range } from "remeda";

import styles from "./week-days.module.scss";

interface WeekDaysProps {
  startDay: DateTime;
  days: number;
}

export function WeekDays({ startDay, days }: WeekDaysProps) {
  return (
    <div className={styles.weekDays}>
      {range(0, days).map((dayIndex) => {
        const day = startDay.plus({ day: dayIndex });

        return (
          <div
            key={day.toISO()}
            className={clsx(styles.day, {
              [styles.isCurrent]: DateTime.now().startOf("day").equals(day),
            })}
          >
            {day.toLocaleString(
              { weekday: "short", day: "2-digit" },
              { locale: "ru" },
            )}
          </div>
        );
      })}
    </div>
  );
}
