export function timeSlotToTimeString(timeSlot: number): string {
  const hours = Math.floor(timeSlot / 4);
  const minutes = (timeSlot % 4) * 15;
  return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
}

export function timeStringToTimeSlot(timeString: string): number {
  const [hours, minutes] = timeString.split(":").map(Number);
  return hours * 4 + Math.floor(minutes / 15);
}

export function generateTimeOptions(): string[] {
  const options: string[] = [];
  for (let slot = 0; slot < 96; slot++) {
    options.push(timeSlotToTimeString(slot));
  }
  return options;
}
