import { motion, useMotionValue } from "framer-motion";
import React, { useEffect, useRef } from "react";

import styles from "./calendar-week.module.scss";

import { estimateCurrentTimeBarHeight } from "./utils";

interface CurrentTimeWeekBarProps {
  height: number;
}

export function CurrentTimeWeekBar({ height }: CurrentTimeWeekBarProps) {
  const currentHeight = useMotionValue(estimateCurrentTimeBarHeight(height));
  const lineRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const intervalId = setInterval(
      () => currentHeight.set(estimateCurrentTimeBarHeight(height)),
      1000,
    );

    return () => clearInterval(intervalId);
  }, [currentHeight, height]);

  useEffect(() => {
    lineRef.current?.scrollIntoView({
      block: "center",
      behavior: "instant",
    });
  }, [lineRef]);

  return (
    <div className={styles.weekNowContainer}>
      <motion.div
        ref={lineRef}
        className={styles.weekNow}
        style={{ y: currentHeight }}
      />
    </div>
  );
}
