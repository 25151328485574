import { DateTime } from "luxon";
import React from "react";

import styles from "./calendar-week.module.scss";

import { WeekDays } from "./week-days";

interface HeaderProps {
  startDay: DateTime;
  days: number;
}

export function Header({ startDay, days }: HeaderProps) {
  return (
    <div
      className={styles.header}
      style={{
        gridTemplateColumns: `[time-start] var(--time-column-width) [time-end week-start] repeat(${days}, [day-start] 1fr [day-end]) [week-end]`,
      }}
    >
      <div className={styles.timeColumn} />
      <WeekDays startDay={startDay} days={days} />
    </div>
  );
}
