import { DateTime } from "luxon";

export function estimateCurrentTimeBarHeight(height: number) {
  const currentMinuteOfTheDay = DateTime.now().diff(
    DateTime.now().startOf("day"),
    "minutes",
  ).minutes;

  return (currentMinuteOfTheDay / 60 / 24) * height;
}
