import { useLiveQuery } from "dexie-react-hooks";
import React from "react";
import { useSearchParams } from "react-router-dom";

import { TaskModel, getOutlineTasks } from "entities/tasks";

import styles from "./outline.module.scss";

import { TaskTree } from "./task-tree";

function getBreadcrumbs(task: TaskModel | null | undefined): TaskModel[] {
  if (!task) return [];
  const breadcrumbs = getBreadcrumbs(task.parent);
  return [...breadcrumbs, task];
}

interface OutlineProps {
  taskIdToSelect?: string;
  onTaskSelected?: () => void;
}

export function Outline({ taskIdToSelect, onTaskSelected }: OutlineProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const zoomTaskId = searchParams.get("zoomTaskId");

  const tasks = useLiveQuery(() => getOutlineTasks(), []);
  const roots = tasks?.filter((t) =>
    zoomTaskId === null
      ? t.task.parentId === null
      : t.task.parentId === zoomTaskId,
  );

  const zoomedTask = tasks?.find((t) => t.task.id === zoomTaskId);
  const breadcrumbs = getBreadcrumbs(zoomedTask);

  if (!tasks || !roots) return null;

  return (
    <div className={styles.outline}>
      {breadcrumbs.length > 0 && (
        <div className={styles.breadcrumbs}>
          <span className={styles.crumb} onClick={() => setSearchParams({})}>
            🏠
          </span>
          {breadcrumbs.map((task, index) => (
            <React.Fragment key={task.id}>
              <span className={styles.separator}>/</span>
              <span
                className={styles.crumb}
                onClick={() =>
                  index === breadcrumbs.length - 1
                    ? undefined
                    : setSearchParams({ zoomTaskId: task.id.toString() })
                }
              >
                {task.title}
              </span>
            </React.Fragment>
          ))}
        </div>
      )}
      <TaskTree
        tasks={tasks}
        roots={roots}
        taskIdToSelect={taskIdToSelect}
        onTaskSelected={onTaskSelected}
      />
    </div>
  );
}
