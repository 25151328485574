import React, { useMemo, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import {
  BsClockHistory,
  BsFillPieChartFill,
  BsListNested,
  BsListTask,
  BsSortUp,
} from "react-icons/bs";
import { LiaUtensilsSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";

import { Calendar } from "features/calendar";
import { Outline } from "features/outline";
import { Priorities } from "features/priorities";
import { ScheduleButton } from "features/scheduler";
import { TimeMaps } from "features/time-maps";
import { Zones } from "features/zones";

import { ExportButton, ImportButton, logCompletedTasks } from "entities/tasks";

import { routePaths } from "shared/router";
import { ControlPanel, Layout } from "shared/ui";

export enum PanelType {
  OUTLINE = "outline",
  PRIORITIES = "priorities",
  ZONES = "zones",
  TIME_MAPS = "time-maps",
}

export function TasksPage() {
  const navigate = useNavigate();

  const [activePanel, setActivePanel] = useState<PanelType>(PanelType.OUTLINE);
  const [selectedTaskId, setSelectedTaskId] = useState<string | undefined>();

  const currentPanel = useMemo(() => {
    switch (activePanel) {
      case PanelType.OUTLINE:
        return (
          <Outline
            taskIdToSelect={selectedTaskId}
            onTaskSelected={() => setSelectedTaskId(undefined)}
          />
        );
      case PanelType.PRIORITIES:
        return <Priorities />;
      case PanelType.ZONES:
        return <Zones />;
      case PanelType.TIME_MAPS:
        return <TimeMaps />;
      default:
        return (
          <Outline
            taskIdToSelect={selectedTaskId}
            onTaskSelected={() => setSelectedTaskId(undefined)}
          />
        );
    }
  }, [activePanel, selectedTaskId]);

  useHotkeys("Meta+Shift+Y", async () => {
    await logCompletedTasks();
  });

  return (
    <div>
      <Layout>
        <Calendar
          onShowTaskInOutline={(taskId) => {
            setSelectedTaskId(taskId);
            setActivePanel(PanelType.OUTLINE);
          }}
        />
        {currentPanel}
      </Layout>
      <ControlPanel>
        <ControlPanel.Button
          onClick={() => setActivePanel(PanelType.OUTLINE)}
          icon={BsListNested}
          label="План"
          hotkey="Meta+1"
        />
        <ControlPanel.Button
          onClick={() => setActivePanel(PanelType.PRIORITIES)}
          icon={BsSortUp}
          label="Приоритеты"
          hotkey="Meta+2"
        />
        <ControlPanel.Button
          onClick={() => setActivePanel(PanelType.ZONES)}
          icon={BsFillPieChartFill}
          label="Зоны"
          hotkey="Meta+3"
        />
        <ControlPanel.Button
          onClick={() => setActivePanel(PanelType.TIME_MAPS)}
          icon={BsClockHistory}
          label="Time Maps"
          hotkey="Meta+4"
        />
        <ScheduleButton />
        <ControlPanel.Divider />
        <ExportButton />
        <ImportButton />
        <ControlPanel.Divider />
        <ControlPanel.Button
          icon={BsListTask}
          onClick={() => navigate(routePaths.TASKS)}
        />
        <ControlPanel.Button
          icon={LiaUtensilsSolid}
          onClick={() => navigate(routePaths.MEALS)}
          hotkey="Meta+M"
        />
      </ControlPanel>
    </div>
  );
}
