import { autoScrollForElements } from "@atlaskit/pragmatic-drag-and-drop-auto-scroll/element";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import { dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { useLiveQuery } from "dexie-react-hooks";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { range } from "remeda";

import { getAllTasks } from "entities/tasks";
import { useZones } from "entities/zones/model/use-zones";

import { database } from "shared/database";

import styles from "./calendar-week.module.scss";

import { CurrentTimeWeekBar } from "./current-time-week-bar";
import { DayEvents } from "./day-events";
import { Grid } from "./grid";
import { TimeColumn } from "./time-column";

interface ScheduleProps {
  startDay: DateTime;
  days: number;
  onShowTaskInOutline?: (taskId: string) => void;
}

export function Schedule({
  startDay,
  days,
  onShowTaskInOutline,
}: ScheduleProps) {
  const { events, tasks } = useLiveQuery(
    async () => {
      return {
        events: await database.events.toCollection().sortBy("startDate"),
        tasks: await getAllTasks(),
      };
    },
    [],
    { events: null, tasks: null },
  );

  const { zones } = useZones();

  const [scheduleElement, setScheduleElement] = useState<HTMLDivElement | null>(
    null,
  );

  const [today, setToday] = useState<DateTime>(DateTime.now().startOf("day"));

  useEffect(
    function refreshWeekOnDayChange() {
      const intervalId = setInterval(() => {
        const actualToday = DateTime.now().startOf("day");
        if (!today.equals(actualToday)) setToday(actualToday);
      }, 1000);
      return () => clearInterval(intervalId);
    },
    [today],
  );

  useEffect(() => {
    if (!scheduleElement) return;

    return combine(
      autoScrollForElements({
        element: scheduleElement,
      }),
      dropTargetForElements({
        element: scheduleElement,
        getData: ({ source }) => ({
          calendarTop: scheduleElement.getBoundingClientRect().top,
          calendarScrollTop: scheduleElement.scrollTop,
          eventPreviewTopOffset: source.data.previewTopOffset,
        }),
      }),
    );
  }, [scheduleElement]);

  if (!tasks || !zones || !events) return null;

  const height = 2000;

  return (
    <div className={styles.schedule} ref={setScheduleElement}>
      <div
        className={styles.container}
        style={{
          gridTemplateColumns: `[time-start] var(--time-column-width) [time-end week-start] repeat(${days}, [day-start] 1fr [day-end]) [week-end]`,
          height,
        }}
      >
        <Grid days={days} />
        <TimeColumn today={today} />
        {range(0, days).map((dayIndex) => {
          const day = startDay.startOf("day").plus({ day: dayIndex });
          return (
            <DayEvents
              key={day.toISO()}
              startDay={startDay}
              dayIndex={dayIndex}
              events={events.filter((tb) =>
                DateTime.fromJSDate(tb.startDate).startOf("day").equals(day),
              )}
              height={height}
              tasks={new Map(tasks.map((t) => [t.id, t]))}
              zones={zones}
              onShowTaskInOutline={onShowTaskInOutline}
            />
          );
        })}
        <CurrentTimeWeekBar height={height} />
      </div>
    </div>
  );
}
