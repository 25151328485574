import {
  Instruction,
  attachInstruction,
  extractInstruction,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item";
import { DropIndicator } from "@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/tree-item";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { DateTime } from "luxon";
import mergeRefs from "merge-refs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useSearchParams } from "react-router-dom";
import invariant from "tiny-invariant";

import {
  Task,
  completeTask,
  createTask,
  moveTask,
  removeTask,
  setTaskPlan,
} from "entities/tasks";
import { collapseTask, expandTask } from "entities/tasks/model";
import { TaskModel } from "entities/tasks/model/task-model";
import { TaskCheckbox } from "entities/tasks/ui/task-checkbox";
import { TaskCollapse } from "entities/tasks/ui/task-collapse";
import { TaskDetails } from "entities/tasks/ui/task-details";
import { TaskNotes } from "entities/tasks/ui/task-notes";
import { TaskSettings } from "entities/tasks/ui/task-settings";
import { TaskTitle } from "entities/tasks/ui/task-title";

import { useKeyboardNavigation } from "shared/libs/keyboard-navigation";
import { ContextMenu, MenuItem } from "shared/ui/context-menu";

import styles from "./task-tree.module.scss";

interface TaskTreeProps {
  tasks: TaskModel[];
  roots: TaskModel[];
  taskIdToSelect?: string;
  onTaskSelected?: () => void;
}

export function TaskTree({
  tasks,
  roots,
  taskIdToSelect,
  onTaskSelected,
}: TaskTreeProps) {
  const [isTaskEditing, setIsTaskEditing] = useState<boolean>(false);
  const [isTaskNew, setIsTaskNew] = useState<boolean>(false);

  useEffect(
    function resetNewTaskOnEditingChange() {
      if (!isTaskEditing) setIsTaskNew(false);
    },
    [isTaskEditing],
  );

  const [selectedTaskId, setSelectedTaskId] = useState<string | null>(null);
  const selectedTask = tasks?.find((t) => t.id === selectedTaskId);

  useEffect(
    function selectAndExpandTask() {
      if (!taskIdToSelect || !tasks) return;

      const taskToSelect = tasks.find((t) => t.id === taskIdToSelect);
      invariant(taskToSelect, "Task to select not found");

      // Expand all parent tasks
      let currentTask = taskToSelect;
      while (currentTask.parent) {
        if (currentTask.parent.isCollapsed) {
          expandTask(currentTask.parent.task);
        }
        currentTask = currentTask.parent;
      }

      setSelectedTaskId(taskIdToSelect);
      onTaskSelected?.();
    },
    [taskIdToSelect, tasks, onTaskSelected],
  );

  const getTaskIndex = useCallback(
    (
      task: TaskModel | null,
      tasks: TaskModel[] | undefined = roots,
    ): number[] | undefined | null => {
      if (task == null) return null;
      if (!tasks) return undefined;

      const taskIndex = tasks.findIndex((t) => t.id === task.id);
      if (taskIndex !== -1) return [taskIndex];

      const subtaskWithTask = tasks
        .map((t, index) => ({
          index,
          subIndex: getTaskIndex(task, t.children),
        }))
        .find((t) => t.subIndex !== undefined);

      if (!subtaskWithTask || !subtaskWithTask.subIndex) return undefined;

      return [subtaskWithTask.index, ...subtaskWithTask.subIndex];
    },
    [roots],
  );

  const selectedTaskIndex = useMemo(
    () => getTaskIndex(selectedTask ?? null) ?? [0],
    [selectedTask, getTaskIndex],
  );

  useKeyboardNavigation({
    isTaskEditing,
    onDisableTaskEditing() {
      setIsTaskEditing(false);
    },
    async onToggleTaskCompletion() {
      if (selectedTask && selectedTask.isCompletable)
        await completeTask(
          selectedTask.task,
          selectedTask.isCompleted ? undefined : new Date(),
        );
    },
    async onIndent() {
      if (!selectedTask) return;

      const parentTaskIndex = selectedTaskIndex.slice(0, -1);
      const currentIndex = selectedTaskIndex.slice(-1)[0];

      const previousTask = tryGetTaskFromIndex(roots, [
        ...parentTaskIndex,
        currentIndex - 1,
      ]);
      if (!previousTask) return;

      const previousTaskChildren = previousTask.children.length;
      await moveTask(
        selectedTask.task,
        previousTask?.task ?? null,
        previousTaskChildren,
      );

      setIsTaskNew(false);
    },
    async onUnindent() {
      if (!selectedTask) return;

      const parentParentTaskIndex = selectedTaskIndex.slice(0, -2);
      const parentTaskIndex = selectedTaskIndex.slice(-2, -1)[0];

      if (parentTaskIndex === undefined) return;

      const parentParentTask = tryGetTaskFromIndex(
        roots,
        parentParentTaskIndex,
      );
      if (parentParentTask === undefined) return;

      await moveTask(
        selectedTask.task,
        parentParentTask?.task ?? null,
        parentTaskIndex + 1,
      );

      setIsTaskNew(false);
    },
    onToggleTaskEditing() {
      setIsTaskEditing((prev) => !prev);
    },
    async onAddTask() {
      const isExpandedParent =
        selectedTask &&
        !selectedTask.isCollapsed &&
        selectedTask.children.length !== 0;

      const parentTask = isExpandedParent
        ? selectedTask
        : (selectedTask?.parent ?? null);

      if (parentTask === undefined) return;

      const position = isExpandedParent
        ? 0
        : selectedTaskIndex.slice(-1)[0] + 1;

      const taskId = await createTask("", parentTask?.id ?? null, position);
      if (!taskId) return;

      setSelectedTaskId(taskId);
      setIsTaskEditing(true);
      setIsTaskNew(true);
    },
    async onCollapse() {
      if (selectedTask) {
        if (selectedTask.children.length === 0 || selectedTask.isCollapsed)
          trySelectTaskWithIndex([...selectedTaskIndex.slice(0, -1)]);
        else await collapseTask(selectedTask.task);
      }
    },
    async onExpand() {
      if (selectedTask) await expandTask(selectedTask.task);
    },
  });

  const pasteHotkeyRef = useHotkeys<HTMLDivElement>(
    ["Meta+V"],
    async () => {
      const clipboardTasks = await navigator.clipboard.readText();

      const tasks = clipboardTasks?.split("\n").map((t) => t.trim());
      if (!tasks || tasks[0] === "") return;

      const parentTask = selectedTask === null ? null : selectedTask?.parent;
      if (parentTask === undefined) return;

      let taskPosition = selectedTaskIndex.slice(-1)[0] + 1;
      for (const taskName of tasks)
        await createTask(taskName, parentTask?.id ?? null, taskPosition++);
    },
    { preventDefault: true },
  );

  const goUpHotkeyRef = useHotkeys<HTMLDivElement>(
    "Up",
    async () => {
      const parentTaskIndex = selectedTaskIndex.slice(0, -1);
      const childIndex = selectedTaskIndex.slice(-1)[0];

      let lastChildInParentIndex = [...parentTaskIndex, childIndex - 1];
      do {
        lastChildInParentIndex = [
          ...lastChildInParentIndex,
          (tryGetTaskWithIndexChildren(roots, lastChildInParentIndex)?.length ??
            0) - 1,
        ];
      } while (trySelectTaskWithIndex(lastChildInParentIndex));
      if (lastChildInParentIndex.length > parentTaskIndex.length + 2) return;

      trySelectFirstExistingTaskIndex(
        [...parentTaskIndex, childIndex - 1],
        parentTaskIndex,
      );
    },
    { preventDefault: true },
  );

  const goDownHotkeyRef = useHotkeys<HTMLDivElement>(
    "Down",
    async () => {
      const parentTaskIndex = selectedTaskIndex.slice(0, -1);
      const childIndex = selectedTaskIndex.slice(-1)[0];

      if (trySelectTaskWithIndex([...parentTaskIndex, childIndex, 0])) return;
      if (trySelectTaskWithIndex([...parentTaskIndex, childIndex + 1])) return;

      while (parentTaskIndex.length > 0)
        if (
          trySelectTaskWithIndex([
            ...parentTaskIndex.slice(0, -1),
            parentTaskIndex.splice(-1)[0] + 1,
          ])
        )
          return;
    },
    { preventDefault: true },
  );

  const moveTaskUpHotkeyRef = useHotkeys<HTMLDivElement>(
    "Meta+Up",
    async () => {
      if (!selectedTask) return;

      await moveTask(
        selectedTask.task,
        selectedTask.parent?.task ?? null,
        Math.max(0, selectedTaskIndex.slice(-1)[0] - 1),
      );
    },
    { preventDefault: true },
  );

  const moveTaskDownHotkeyRef = useHotkeys<HTMLDivElement>(
    "Meta+Down",
    async () => {
      if (!selectedTask) return;

      const currentIndex = selectedTaskIndex.slice(-1)[0];

      await moveTask(
        selectedTask.task,
        selectedTask.parent?.task ?? null,
        Math.min(selectedTask.siblings.length - 1, currentIndex + 1),
      );
    },
    { preventDefault: true },
  );

  const flexiblePlanHotkeyRef = useHotkeys<HTMLDivElement>(
    "Meta+F",
    function ToggleFlexiblePlan() {
      if (!selectedTask) return;

      setTaskPlan(selectedTask.task, {
        type:
          selectedTask.task.plan?.type === "flexible"
            ? "notPlanned"
            : "flexible",
      });
    },
    { preventDefault: true },
  );

  return (
    <div
      ref={mergeRefs(
        pasteHotkeyRef,
        goUpHotkeyRef,
        goDownHotkeyRef,
        moveTaskUpHotkeyRef,
        moveTaskDownHotkeyRef,
        flexiblePlanHotkeyRef,
      )}
      className={styles.taskTree}
    >
      {drawTasksForParent(
        tasks ?? [],
        roots ?? [],
        [],
        isTaskNew,
        selectedTaskId,
        setSelectedTaskId,
        isTaskEditing,
        setIsTaskEditing,
        async () => {
          if (!selectedTask) return;

          const parentIndex = selectedTaskIndex.slice(0, -1);
          const currentIndex = selectedTaskIndex.slice(-1)[0];

          await removeTask(selectedTask.task);

          trySelectFirstExistingTaskIndex(
            [...parentIndex, currentIndex + 1],
            [...parentIndex, currentIndex - 1],
            parentIndex,
          );
        },
      )}
    </div>
  );

  function trySelectFirstExistingTaskIndex(...indexes: number[][]) {
    for (const index of indexes) if (trySelectTaskWithIndex(index)) break;
  }

  function trySelectTaskWithIndex(index: number[]) {
    const task = tryGetTaskFromIndex(roots, index);
    if (!task) return false;

    setSelectedTaskId(task.id);
    return true;
  }
}

function drawTasksForParent(
  tasks: TaskModel[],
  parentTasks: TaskModel[],
  indexPrefix: number[],
  isTaskNew: boolean,
  selectedTaskId: string | null,
  setSelectedTaskId: (id: string) => void,
  isTaskEditing: boolean,
  setIsTaskEditingId: (isEditing: boolean) => void,
  onDelete: () => void,
) {
  return parentTasks.map((task, index) => (
    <OutlineTaskComponent
      key={task.id}
      task={task}
      index={[...indexPrefix, index]}
      isLast={index === parentTasks.length - 1}
      isTaskNew={isTaskNew}
      tasks={tasks}
      selectedTaskId={selectedTaskId}
      setSelectedTaskId={setSelectedTaskId}
      isTaskEditing={isTaskEditing}
      setIsTaskEditing={setIsTaskEditingId}
      onDelete={onDelete}
    />
  ));
}

interface OutlineTaskProps {
  tasks: TaskModel[];
  task: TaskModel;
  index: number[];
  isLast: boolean;
  isTaskNew: boolean;
  selectedTaskId: string | null;
  setSelectedTaskId: (id: string) => void;
  isTaskEditing: boolean;
  setIsTaskEditing: (isEditing: boolean) => void;
  onDelete: () => void;
}

function OutlineTaskComponent({
  tasks,
  task,
  index,
  isLast,
  isTaskNew,
  selectedTaskId,
  setSelectedTaskId,
  isTaskEditing,
  setIsTaskEditing,
  onDelete,
}: OutlineTaskProps) {
  const [, setSearchParams] = useSearchParams();

  const [lastDragOver, setLastDragOver] = useState<DateTime | null>(null);
  const [instruction, setInstruction] = useState<Instruction | null>(null);
  const [taskElement, setTaskElement] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!taskElement) return;

    return combine(
      draggable({
        element: taskElement,
        getInitialData: () => ({ type: "task", id: task.id }),
      }),
      dropTargetForElements({
        element: taskElement,
        getIsSticky: () => true,
        getData: ({ input, element }) =>
          attachInstruction(
            {},
            {
              input,
              element,
              currentLevel: index.length - 1,
              indentPerLevel: 0,
              mode: isLast
                ? "last-in-group"
                : task.isCollapsed
                  ? "standard"
                  : "expanded",
            },
          ),
        onDrag: async ({ self }) => {
          setInstruction(extractInstruction(self.data));

          if (
            lastDragOver &&
            DateTime.now().diff(lastDragOver).milliseconds > 1000 &&
            task.isParent &&
            task.isCollapsed
          )
            await expandTask(task.task);
        },
        onDragEnter: ({ self }) => {
          setInstruction(extractInstruction(self.data));
          setLastDragOver(DateTime.now());
        },
        onDragLeave: () => {
          setInstruction(null);
          setLastDragOver(null);
        },
        onDrop: ({ source }) => {
          const sourceTask = tasks?.find((t) => t.id === source.data.id);
          if (!sourceTask) return;

          const taskParent = task.parent?.task ?? null;
          const taskSiblingIndex = task.siblings.findIndex(
            (t) => t.id === task.id,
          );
          const sourceTaskSiblingIndex = task.siblings.findIndex(
            (t) => t.id === sourceTask.id,
          );
          const isSourceTaskEarlierThanTarget =
            sourceTaskSiblingIndex !== -1 &&
            sourceTaskSiblingIndex < taskSiblingIndex;

          switch (instruction?.type) {
            case undefined:
              break;
            case "make-child":
              if (source.data.id !== task.id)
                void moveTask(sourceTask.task, task.task, 0);
              break;
            case "reorder-above":
              void moveTask(
                sourceTask.task,
                taskParent,
                taskSiblingIndex - (isSourceTaskEarlierThanTarget ? 1 : 0),
              );
              break;
            case "reorder-below":
              void moveTask(
                sourceTask.task,
                taskParent,
                taskSiblingIndex + 1 - (isSourceTaskEarlierThanTarget ? 1 : 0),
              );
              break;
            default:
              throw new Error(`Invalid instruction type ${instruction?.type}`);
          }

          setInstruction(null);
          setLastDragOver(null);
        },
      }),
    );
  }, [
    index.length,
    instruction,
    isLast,
    lastDragOver,
    task,
    task.isCollapsed,
    taskElement,
    tasks,
  ]);

  return (
    <>
      <ContextMenu
        content={
          <>
            <MenuItem
              shortcut="Meta+."
              onClick={() => {
                completeTask(
                  task.task,
                  task.isCompleted ? undefined : new Date(),
                );
              }}
            >
              {task.isCompleted
                ? "Отметить как незавершенное"
                : "Отметить как завершенное"}
            </MenuItem>
            {task.isParent && (
              <MenuItem
                onClick={() =>
                  setSearchParams({ zoomTaskId: task.id.toString() })
                }
              >
                Фокус
              </MenuItem>
            )}
            <MenuItem onClick={onDelete} shortcut="Meta+Backspace">
              Удалить
            </MenuItem>
          </>
        }
      >
        <Task
          key={task.id}
          task={task}
          level={index.length - 1}
          className={styles.task}
          isSelected={selectedTaskId === task.id}
          isEditing={selectedTaskId === task.id && isTaskEditing}
          onEditToggle={() => setIsTaskEditing(!isTaskEditing)}
        >
          <TaskDetails
            ref={setTaskElement}
            isSelected={selectedTaskId === task.id}
            onSelect={() => setSelectedTaskId(task.id)}
            isDragging={instruction !== undefined}
            className={styles.taskDetails}
            isNewTask={isTaskNew}
            onDelete={onDelete}
          >
            <TaskCheckbox />
            <TaskCollapse />
            <TaskTitle />
            <TaskSettings />
            <TaskNotes
              className={styles.notes}
              task={task}
              isEditing={selectedTaskId === task.id && isTaskEditing}
            />
            {instruction && <DropIndicator instruction={instruction} />}
          </TaskDetails>
        </Task>
      </ContextMenu>
      {(!task.isCollapsed || task.allChildrenCompleted) &&
        drawTasksForParent(
          tasks,
          task.children,
          index,
          isTaskNew,
          selectedTaskId,
          setSelectedTaskId,
          isTaskEditing,
          setIsTaskEditing,
          onDelete,
        )}
    </>
  );
}

function tryGetTaskWithIndexChildren(
  tasks: TaskModel[] | undefined,
  index: number[],
) {
  const task = tryGetTaskFromIndex(tasks, index);
  if (!task) return undefined;

  return task.children;
}

function tryGetTaskFromIndex(tasks: TaskModel[] | undefined, index: number[]) {
  if (!tasks) return tasks;
  if (index.length === 0) return null;

  const currentIndex = index[0];
  if (currentIndex < 0 || currentIndex >= tasks.length) return undefined;

  if (index.length === 1) return tasks[currentIndex];

  if (tasks[currentIndex].isCollapsed) return null;

  const childTasks = tasks[currentIndex]?.children;
  if (!childTasks) return childTasks;

  return tryGetTaskFromIndex(childTasks, index.slice(1));
}
